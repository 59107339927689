import { Subscription } from "./use-subscriptions";
import Dayjs from "dayjs";

export function isSubscriptionInTrial(subscription: Subscription) {
  if (!subscription) {
    return false;
  }

  return Dayjs(subscription.trialEndDate).isAfter(Dayjs());
}

export function getSubscriptionUrl(subscription: Subscription, fallBackSubscriptionId?: string): string {
  if (!subscription?.managedByGlobal) {
    return `/subscriptions/${subscription?.id || fallBackSubscriptionId}`;
  } else {
    return `https://${process.env.NEXT_PUBLIC_GLOBAL_CONSOLE_DOMAIN}/subscriptions/${subscription.id}`;
  }
}

export function getServicePlanUrl(subscription: Subscription): string {
  if (!subscription?.managedByGlobal) {
    return ``;
  } else {
    return `https://${process.env.NEXT_PUBLIC_GLOBAL_CONSOLE_DOMAIN}/billing/${subscription.id}/service-plan`;
  }
}

export function globalConsoleUrl(): string {
  return `https://${process.env.NEXT_PUBLIC_GLOBAL_CONSOLE_DOMAIN}`;
}

export function migrationUrl(subscriptionId): string {
  return `https://${process.env.NEXT_PUBLIC_GLOBAL_CONSOLE_DOMAIN}/subscriptions/${subscriptionId}/migrate`;
}

export function daysLeftInTrial(subscription: Subscription): number {
  return Dayjs(subscription.trialEndDate).diff(Dayjs(), 'days') + 1;
}
