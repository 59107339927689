import {
  ApiKeyHelpContent, ApplicationConfigurationHelpContent, ApplicationListHelpContent, ApplicationOverviewHelpContent,
  BlueprintStatusHelpContent, CreateTenantHelpContent, ImportTenantHelpContent, ImportVersionHelpContent, CreateVersionHelpContent,
  TenantListHelpContent, VersionOverviewHelpContent, SnapshotListHelpContent
} from 'components/help-content';


export const sideBarHelpContent = {
  "/": {
    title: "Applications list",
    content: <ApplicationListHelpContent />
  },
  "/products/[productId]": {
    title: "Application overview",
    content: <ApplicationOverviewHelpContent />
  },
  "/products/[productId]/import": {
    title: "Import an existing site as a Version",
    content: <ImportVersionHelpContent />
  },
  "/products/[productId]/import-tenant": {
    title: "Import an existing site as a Tenant",
    content: <ImportTenantHelpContent />
  },
  "/products/[productId]/versions/new": {
    title: "Create a new version",
    content: <CreateVersionHelpContent />
  },
  "/products/[productId]/versions/[versionId]": {
    title: "Version overview",
    content: <VersionOverviewHelpContent />
  },
  "/products/[productId]/versions/[versionId]/snapshots": {
    title: "Snapshot overview",
    content: <SnapshotListHelpContent />
  },
  "/products/[productId]/tenants/new": {
    title: "Create a new Tenant",
    content: <CreateTenantHelpContent />
  },
  "/products/[productId]/tenants": {
    title: "Tenant list overview",
    content: <TenantListHelpContent />
  },
  "/products/[productId]/configuration": {
    title: "Application Configuration",
    content: <ApplicationConfigurationHelpContent />
  },
  "/products/[productId]/apikeys": {
    title: "Application overview",
    content: <ApiKeyHelpContent />
  },
  "/blueprints/status": {
    title: "Resource status",
    content: <BlueprintStatusHelpContent />
  },
}
