import fetcher from '../fetcher'

export function fetchChargebeeSession(subscriptionId: string): any {
  return fetcher(`/api/v1/chargebee/session/${subscriptionId}`);
}

export function createChargebeeCustomer(subscriptionId: string, mainEmail: string, companyName: string): any {
  return fetch('/api/v1/chargebee/customers', {
    method: 'POST',
    body: JSON.stringify({
      subscriptionId,
      mainEmail,
      companyName,
    })
  });
}
