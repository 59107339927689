import useSWR, { SWRResponse } from "swr";

interface UserProfileSWRResponse extends SWRResponse<IUserProfile, Error> {
  updatePreferredRegion: (region: string) => Promise<void>;
  updateProductTourParts: (parts: Partial<IProductTourParts>) => Promise<void>;
  updateFeatureTourParts: (parts: Partial<IFeatureTourParts>) => Promise<void>;
  resetProductTourParts: () => Promise<void>;
}

export interface IProductTourParts {
  productPageDone: boolean;
  versionPageDone: boolean;
  createVersionDone: boolean;
  versionCreatedDone: boolean;
  createTenantDone: boolean;
  tenantCreatedDone: boolean;
  createDeployDone: boolean;
  skipDeploy: boolean;
  skipped: boolean;
}

export interface IUserProfile {
  productTour: IProductTourParts,
  preferredRegion: string
  featureTours: IFeatureTourParts;
}

export interface IFeatureTourParts {
  tenantPluginManagementFeatureTour: boolean
}

export const emptyUserProfile: IUserProfile = {
  productTour: {
    productPageDone: false,
    versionPageDone: false,
    createVersionDone: false,
    versionCreatedDone: false,
    createTenantDone: false,
    createDeployDone: false,
    skipDeploy: false,
    tenantCreatedDone: false,
    skipped: false,
  },
  preferredRegion: null,
  featureTours: {
    tenantPluginManagementFeatureTour: false
  }
}

export const conservativeUserProfile: IUserProfile = {
  productTour: {
    productPageDone: false,
    versionPageDone: false,
    createVersionDone: false,
    versionCreatedDone: false,
    createTenantDone: false,
    createDeployDone: false,
    skipDeploy: false,
    tenantCreatedDone: false,
    skipped: true,
  },
  preferredRegion: null,
  featureTours: {
    tenantPluginManagementFeatureTour: true
  }
}

export default function useUserProfile(): UserProfileSWRResponse {
  const swr = useSWR(`/api/v1/profile`, {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });

  return {
    ...swr,
    updatePreferredRegion: async (preferredRegion: string) => {
      const body: Pick<IUserProfile, 'preferredRegion'> = {
        preferredRegion,
      }
      await patchProfile(body);
      swr.mutate({ ...swr.data, preferredRegion: preferredRegion }, true);
    },
    updateProductTourParts: async (parts: Partial<IProductTourParts>) => {
      const body: Pick<IUserProfile, 'productTour'> = {
        productTour: {
          ...((swr.data || {}).productTour || {}),
          ...parts
        }
      }
      await patchProfile(body);
      swr.mutate({ ...swr.data, productTour: { ...(swr.data || {}).productTour, ...parts } }, true);
    },
    updateFeatureTourParts: async (parts: Partial<IFeatureTourParts>) => {
      const body: Pick<IUserProfile, 'featureTours'> = {
        featureTours: {
          ...((swr.data || {}).featureTours || {}),
          ...parts
        }
      }
      await patchProfile(body);
      swr.mutate({ ...swr.data, featureTours: { ...((swr.data || {}).featureTours || {}), ...parts } }, true);
    },
    resetProductTourParts: async () => {
      const body: Pick<IUserProfile, 'productTour'> = {
        productTour: {
          productPageDone: false,
          versionPageDone: false,
          createVersionDone: false,
          versionCreatedDone: false,
          createTenantDone: false,
          createDeployDone: false,
          skipDeploy: false,
          tenantCreatedDone: false,
          skipped: false,
        }
      }
      await patchProfile(body);
      swr.mutate({ ...swr.data, productTour: body.productTour }, true);
    }
  };
}

async function patchProfile(body: any): Promise<void> {
  try {
    const response = await fetch(`/api/v1/profile`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw error;
  }
}