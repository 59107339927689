import ExternalLink from "components/external-link";

export default function ApplicationConfigurationHelpContent() {
  return <>
    <p>The <b>Application Configuration</b> determines the available resources to serve all tenants in this application.</p>
    <p>The <b>Container configuration</b> determines the <i>quality</i> of the resources that serve the tenants and thus limit the maximum amount of memory used by the tenants and the processing speed in terms of CPU.
      Computational heavy applications such as e-commerce solutions, CRMs and LMSs, require larger container configurations, whereas simple portfolio sites have less requirements.</p>
    <p>The <b>Scaling configuration</b> determines the <i>quantity</i> of the resources that serve the tenants. A higher scale implies more containers (processing units) that can serve the tenants in this application.
      For applications that contain busy tenants a higher scale is advised to guarantee availability.</p>
    <p>The <b>metered</b> scaling approach allows for a more custom tailoring of the scaling configuration.</p>
    <p>These settings mostly determine usage related costs.</p>

    <hr className="text-brand-primary" />

    <p>Related documentation: </p>
    <ul style={{ listStyleType: "none" }}>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/managing-the-application-configuration" label="Managing the Application configuration" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/monitoring-costs-in-your-subscription" label="Monitoring costs" /></li>
      <li><ExternalLink href="https://wildcloud.com/pricint" label="Pricing page" /></li>
    </ul>
  </>
}