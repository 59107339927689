import ExternalLink from "components/external-link";

export default function VersionOverviewHelpContent() {
  return <>
    <p>The version detail screen contains information about the version's name, creation and update dates, WordPress and PHP version as well as the status of the Version editor.</p>
    <p>The tenant lists allow you to quickly see which tenants are running on this version, as well as plugin (de)activation in bulk and move tenants to another version.</p>
    <p>The snapshots list gives you an overview of the different snapshots available on this version.</p>

    <hr className="text-brand-primary" />

    <p>Related documentation: </p>
    <ul style={{ listStyleType: "none" }}>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/versions" label="Version documentation" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/shutting-down-the-version-editor" label="Shutting down a version editor" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/deploy-a-version-snapshot" label="Deploying a version snapshot" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/upgrading-wordpress-php-version" label="Upgrading WordPress and PHP" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/set-a-version-as-production" label="The production version" /></li>
    </ul>
  </>
}