import useRegionInfo from "lib/use-info";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";

const regions = [
  {
    regionName: 'EU1',
    label: 'EU1 (Ireland)',
    url: 'console.eu1.wpcs.io'
  },
  {
    regionName: 'US1',
    label: 'US1 (N. Virginia)',
    url: 'console.us1.wpcs.io'
  },
]

export default function RegionSwitcher() {
  const { data } = useRegionInfo();

  const router = useRouter();
  const route = router.pathname.includes("[productId]") ? "" : router.asPath;

  const currentRegion = useMemo(() => {
    if (!data) {
      return null;
    }

    return regions.find(r => r.regionName.toUpperCase() === data.name.toUpperCase());
  }, [data]);

  if (!currentRegion) {
    return null;
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle className="btn-fully-outline-secondary me-3" variant="outline-secondary" id="dropdown-basic">
          Current region: {currentRegion.regionName}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {regions.map(region => {
            const isCurrent = region.regionName === currentRegion.regionName;

            return (
              <Dropdown.Item key={region.regionName} disabled={isCurrent} href={`https://${region.url}${route}`}>
                {region.label}{isCurrent && ' - current'}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
