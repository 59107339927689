import ExternalLink from "components/external-link";

export default function ApplicationListHelpContent() {
  return <>
    <p>On this page you can see the applications, their number of versions and number of tenants for specifically the {process.env.NEXT_PUBLIC_REGION_NAME} region.</p>
    <p>A warning sign may appear on the application row when backup creation is failing or there are tenants or versions in an error state. If you encounter this it is best to contact support.</p>

    <hr className="text-brand-primary" />

    <p>Related documentation: </p>
    <ul style={{ listStyleType: "none" }}>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/create-an-application-overview" label="Creating an application" /></li>
    </ul>
  </>
}