import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { migrationUrl } from "lib/helpers";
import ExternalLink from "./external-link";
import { Subscription, useSubscriptions } from "lib/use-subscriptions";
import { SortOrder, sortByDateCreated } from "lib/sort-functions";
import useFeatureClaims from "lib/use-feature-claims";

export default function PricingMigrationNagList() {
  const { data: subscriptions } = useSubscriptions();

  const subscriptionsToNag = useMemo<Subscription[]>(() => {
    if(!subscriptions) {
      return [];
    }

    return subscriptions
      .sort(sortByDateCreated(SortOrder.ASCENDING))
      .filter(sub => {
        return !sub.managedByGlobal // Sub is not yet managed by global, so needs to be migrated
          && !sub.partnerId // If subscription has a partner associated with it, never show any billing related nags.
          && !sub.excludedFromBilling // If sub is excluded from billing, obviously no billing nag should be shown.
      })
      .slice(0, 3) // Show max nags at the same time so the screen is never flooded.
  }, [subscriptions])

  const [renderNags, setRenderNags] = useState(false);
  const { data: featureClaims } = useFeatureClaims();

  useEffect(() => {
    if (!featureClaims) {
      return;
    }

    setRenderNags(!featureClaims.includes('Subscription.Manage'))
  }, [featureClaims]);

  if (!renderNags) {
    return null
  }

  return (
    <Container>
      {subscriptionsToNag.map(sub => (
        <PricingMigrationNag
          subscription={sub}
          key={`migrate-nag-${sub.id}`}
        />
      ))}
    </Container>
  )
}

export function PricingMigrationNag({
  subscription,
}: {
  subscription: Subscription,
}) {
  return (
    <Alert>
      <Row>
        <Col sm={9}>
          <p className="text-bold">New pricing model for {subscription.name}</p>
          <p>
            Move your subscription {subscription.name} over to the new <ExternalLink href="https://wpcs.io/pricing" variant="underline">Pricing model</ExternalLink> and enjoy advantages like custom Scaling and Container configurations.
          </p>
        </Col>
        <Col sm={3} className="d-flex align-items-center justify-content-end">
          <Button href={migrationUrl(subscription.id)}>
              Move to new pricing
          </Button>
        </Col>
      </Row>
    </Alert>
  )
}
