import { Nav, } from 'react-bootstrap';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useProduct from 'lib/use-product';
import { useMemo, useState } from 'react';
import Link from 'next/link';
import { useSubscriptions } from 'lib/use-subscriptions';
import { Claim, ServicePlan } from 'lib/types';
import ClaimContainer from './claim-container';
import { getSubscriptionUrl, globalConsoleUrl } from 'lib/helpers';
import ServicePlanContainer from 'components/service-plan-container';
import Sidebar from 'components/side-bar';

export default function SideMenu() {
  const router = useRouter()

  const {
    productId,
  } = router.query as { productId: string }

  const { data: product } = useProduct(productId as string);
  const { data: subscriptions } = useSubscriptions();

  const [showSupport, setShowSupport] = useState(false);
  const toggleSidebar = () => {
    setShowSupport(!showSupport);
  };


  const globalConsole = useMemo(() => subscriptions != null && subscriptions.every(s => s.managedByGlobal), [subscriptions]);

  const bottomItems = [
    {
      label: 'Resources',
      link: 'https://wildcloud.com/resources/',
    },
    {
      label: 'Support',
      onClick: toggleSidebar
    },
  ]

  const productItems = useMemo<{ label: string, link: string, claim?: Claim, servicePlans?: ServicePlan[], claimProductId?: string }[]>(() => {
    return [
      {
        label: product?.name || 'Overview',
        link: `/products/${productId}`,
      },
      {
        label: 'Configuration',
        link: `/products/${productId}/configuration`,
      },
      {
        label: 'Performance',
        link: `/products/${productId}/performance`,
        servicePlans: ['enterprise', 'professional', 'turbo-plan', 'pro-retainer']
      },
      {
        label: 'Tenants',
        link: `/products/${productId}/tenants`,
      },
      {
        label: 'API Keys',
        link: `/products/${productId}/apikeys`,
      },
      {
        label: 'Events',
        link: `/products/${productId}/events`,
        claim: 'ReadEvents'
      },
    ];
  }, [
    productId,
    product,
    subscriptions
  ]);

  return <div className="container bg-image pt-2 d-flex flex-column justify-content-between h-100">
    <Nav defaultActiveKey="home" className="flex-column">
      <Nav.Item>
        <Link href="/products" passHref>
          <Nav.Link eventKey="versions" className="text-center">
            <Image
              src="/img/wildcloud_by_wpcs_logo.svg"
              alt=""
              width={250}
              height={137}
              objectFit="contain"
            />
          </Nav.Link>
        </Link>
      </Nav.Item>
      <Nav.Item>
        {(subscriptions == null || subscriptions.length > 1) && <Link href="/subscriptions" passHref>
          <Nav.Link eventKey="subscriptions" >
            <div className="menu-item level-1 my-2">Subscriptions</div>
          </Nav.Link>
        </Link>}
        {subscriptions != null && subscriptions.length == 1 &&
          <Link href={getSubscriptionUrl(subscriptions[0])} passHref>
            <Nav.Link eventKey="subscriptions" >
              <div className="menu-item level-1 my-2">My Subscription</div>
            </Nav.Link>
          </Link>
        }
      </Nav.Item>
      <Nav.Item>
        <Link href={globalConsole ? globalConsoleUrl() : '/products'} passHref>
          <Nav.Link eventKey="versions" >
            <div className="menu-item level-1 my-2">{globalConsole ? 'Applications' : 'All products'}</div>
          </Nav.Link>
        </Link>
      </Nav.Item>
      {productId && <div className="border-top">
        <Nav.Item>
          <div className="menu-item menu-header m-2">{globalConsole ? 'Current application:' : 'Current product:'}</div>
        </Nav.Item>
        {productItems.map((item, i) => (
          <Nav.Item key={`side-menu-sub-item-${i}`}>
            {item.claim != null &&
              <ClaimContainer claim={item.claim} productId={item.claimProductId}>
                <Link href={item.link} passHref>
                  <Nav.Link eventKey={item.label.replaceAll(' ', '-')}>
                    <div className="menu-item level-1 my-2">{item.label}</div>
                  </Nav.Link>
                </Link>
              </ClaimContainer>
            }
            {item.claim == null && item.servicePlans != null &&
              <ServicePlanContainer productId={productId} serviceLevels={['enterprise', 'professional', 'pro-retainer', 'turbo-plan']}>
                <Link href={item.link} passHref>
                  <Nav.Link eventKey={item.label.replaceAll(' ', '-')}>
                    <div className="menu-item level-1 my-2">{item.label}</div>
                  </Nav.Link>
                </Link>
              </ServicePlanContainer>
            }
            {item.claim == null && item.servicePlans == null &&
              <Link href={item.link} passHref>
                <Nav.Link eventKey={item.label.replaceAll(' ', '-')}>
                  <div className="menu-item level-1 my-2">{item.label}</div>
                </Nav.Link>
              </Link>
            }

          </Nav.Item>
        ))}
      </div>
      }
    </Nav >
    <Nav className="flex-column mb-3">
      {bottomItems.map((item, i) => (
        <Nav.Item key={`side-menu-bottom-item-${i}`}>
          {item.link &&
            <Nav.Link eventKey={item.label} href={item.link} target="_blank">
              <div className="menu-item level-1 my-2">{item.label}</div>
            </Nav.Link>
          }
          {item.onClick &&
            <Nav.Link eventKey={item.label} onClick={item.onClick}>
              <div className="menu-item level-1 my-2">{item.label}</div>
            </Nav.Link>
          }
        </Nav.Item>
      ))}
    </Nav>

    <Sidebar isOpen={showSupport} toggleSidebar={toggleSidebar} />

    <style jsx>{`
      .container {
        font-family: Eastman-Regular, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      }

      .menu-item {
        color: #FCFDED;
        font-size: 1.1rem;
      }

      .menu-header {
        font-size: 0.8rem;
        opacity: 0.6;
      }
      
      .menu-header:hover {
        color: #FCFDED;
      }

      .menu-item:hover {
        color: white;
      }

      .level-2 {
        margin-left: 0.7rem;
      }

      .bg-image {
        background: url('/img/side-menu-bg.jpg') no-repeat;
        background-position: center;
        background-size: cover;
      }
    `}</style>
  </div >
}
