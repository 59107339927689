import Link from "next/link";
import { NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function NavbarButtonBase({
  label,
  href,
  disabled = false,
  disabledExplanation = '',
}) {
  if (!disabled) {
    return renderNavDropdown(label, href)
  }

  return (
    <OverlayTrigger
      placement="left"
      overlay={<Tooltip id="disabled-explanation">{disabledExplanation}</Tooltip>}
    >
      <span className="d-inline-block">
        {renderNavDropdown(label, href, disabled)}
      </span>
    </OverlayTrigger>
  )
}

function renderNavDropdown(text: string, href: string, disabled = false) {
  return (
    <Link href={href} passHref>

      <NavDropdown.Item
        disabled={disabled}
        style={disabled ? { pointerEvents: 'none' } : null}
      >
        {text}
      </NavDropdown.Item>
    </Link>
  )
}