
export function findContent(contentMap, currentRoute): {title: string, content: string} | null {
  let bestMatch: string | null = null;
  
  // Iterate through the keys and find the best match
  Object.keys(contentMap).forEach((path) => {
    if (currentRoute.startsWith(path) && (!bestMatch || path.length > bestMatch.length)) {
      bestMatch = path;
    }
  });

  // Return the content for the best match
  return bestMatch ? contentMap[bestMatch] : null;
}
