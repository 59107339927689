import type { AppProps } from 'next/app'
import '../styles/globals.scss'
import { SessionProvider } from 'next-auth/react'
import React, { useState } from 'react';
import { SWRConfig } from 'swr'
import fetcher from 'lib/fetcher'
import NavBar from 'components/navbar'
import SessionChecker from 'components/session-checker'
import SideMenu from 'components/side-menu'
import { useRouter } from 'next/router';
import SplashScreen from 'components/splash-screen';
import Favicons from 'components/favicons';
import Head from 'next/head';
import BillingNag from 'components/billing-nag';
import RegionRedirecter from 'components/region-redirecter';
import ServicePlanNag from 'components/service-plan-nag';
import PricingMigrationNag from 'components/pricing-migration-nag';
import QuarantinedNag from 'components/quarantined-nag';
import HelpButton from 'components/buttons/help-button';

function MyApp({ Component, pageProps }: AppProps) {

  const [loggedIn, updateLoggedIn] = useState(false)
  const router = useRouter()

  const unsecuredRoutes = ['/accept-terms-of-service']
  const isRouteSecure = !unsecuredRoutes.includes(router.route)

  const fullPageRoutes = ['/start-trial/[subscriptionId]', '/start-trial/[subscriptionId]/start', '/subscriptions/[subscriptionId]/cancel'];
  const isFullPageRoute = fullPageRoutes.includes(router.route);

  const showNag = !['/subscriptions/[subscriptionId]/cancel', '/subscriptions/[subscriptionId]'].includes(router.route);

  return (
    <SessionProvider session={pageProps.session}>
      <SWRConfig value={{
        fetcher,
        refreshInterval: 15 * 1000,
        dedupingInterval: 10 * 1000,
      }}>
        <Head>
          <title>Wildcloud Console</title>
          <script src="/script/hj.js" async />
        </Head>
        <Favicons />
        <SessionChecker
          onLoggedIn={() => updateLoggedIn(true)}
          allowNoSession={!isRouteSecure}
        />
        {loggedIn && isRouteSecure &&
          !isFullPageRoute && <>
            <RegionRedirecter />

            <div className="side-menu-container">
              <SideMenu />
            </div>

            <div className="main-content-container">
              <NavBar />
              {showNag && <>
                <BillingNag />
                <ServicePlanNag />
                <PricingMigrationNag />
              </>}
              <QuarantinedNag />

              <Component {...pageProps} />
            </div>

            <HelpButton />
          </>
        }

        {isFullPageRoute &&
          <Component {...pageProps} />
        }
        {!loggedIn && isRouteSecure && <SplashScreen />}
        {!isRouteSecure && <Component {...pageProps} />}

      </SWRConfig>
      <style jsx>{`
        .side-menu-container {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          width: 220px;
        }

        .main-content-container {
          margin-left: 220px;
        }
      `}</style>
    </SessionProvider>
  )
}

export default MyApp
