import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';
import Breadcrumbs from './breadcrumbs';
import NavbarTenantCreateButton from './buttons/navbar-tenant-create';
import ClaimContainer from './claim-container';
import RegionSwitcher from './region-switcher';

export default function NavBar() {
  const { data: session } = useSession()
  const router = useRouter()

  const {
    productId,
  } = router.query

  const logout = async () => {
    await signOut({ redirect: false });
    router.push(`https://${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}&returnTo=https%3A%2F%2Fwpcs.io`);
  };

  return <div className="navbar-container dropshadow-1 mb-3">
    <Navbar expand="lg">
      <Nav className="me-auto">
        <Breadcrumbs />
      </Nav>
      <Nav className="ms-auto pe-3">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          {session && productId && <>
            <NavDropdown id="nav-general-create-dropdown" title="Create" className=" me-3">
              <NavDropdown.Header>In this product</NavDropdown.Header>
              <Link href={`/products/${productId}/versions/new`} passHref><NavDropdown.Item >New version</NavDropdown.Item></Link>
              <NavbarTenantCreateButton productId={productId} />
            </NavDropdown>
          </>}
          {session && <>
            <RegionSwitcher />
            <NavDropdown align="end" id="nav-general-profile-dropdown" title={
              <img className="thumbnail-image"
                src={session.user.image}
                width="35px"
                alt="User picture"
              />
            }>
              <NavDropdown.Item disabled><>
                <div>{session.user.name}</div>
                <div><small>{session.user.email}</small></div>
              </></NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href={`https://${process.env.NEXT_PUBLIC_GLOBAL_CONSOLE_DOMAIN}/profile`}>Profile</NavDropdown.Item>
              <NavDropdown.Item href="/subscriptions">My subscriptions</NavDropdown.Item>
              <NavDropdown.Item onClick={async () => { await logout(); }}>Sign out</NavDropdown.Item>
              <ClaimContainer claim='Application.Info'>
                <NavDropdown.Divider />
                <NavDropdown.Item disabled>
                  Console version: v{process.env.NEXT_PUBLIC_PACKAGE_VERSION}
                </NavDropdown.Item>
              </ClaimContainer>
            </NavDropdown>
          </>}
          {!session && <>
            <Nav.Link href="/api/auth/signin" className="text-white">Sign in</Nav.Link>
          </>}
        </Navbar.Collapse>
      </Nav>
    </Navbar>

    <style jsx>{`
      .thumbnail-image { border-radius: 50%; }


    `}</style>
  </div>
}
