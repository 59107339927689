import useProduct from 'lib/use-product';
import useSnapshot from 'lib/use-snapshot';
import { useSubscription } from 'lib/use-subscriptions';
import { useTenant } from 'lib/use-tenants';
import useVersion from 'lib/use-version';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Breadcrumb,
} from 'react-bootstrap';

export default function Breadcrumbs() {
  const router = useRouter()

  const {
    query: {
      subscriptionId,
      productId,
      versionId,
      tenantId,
      snapshotId,
    },
  }: {
    query: {
      subscriptionId?: string;
      productId?: string;
      versionId?: string;
      tenantId?: string;
      snapshotId?: string;
    }
  } = router

  const { data: productData } = useProduct(productId)
  const { data: versionData } = useVersion(productId, versionId)
  const { data: tenantData } = useTenant(productId, tenantId)
  const { data: snapshotData } = useSnapshot(productId, versionId, snapshotId)
  const { data: subscriptionData } = useSubscription(subscriptionId)

  const data = {
    productId: productData?.name ?? `${productId?.substring(0, 7)}...` ?? 'current product',
    versionId: versionData?.name ?? `${versionId?.substring(0, 7)}...` ?? 'current version',
    tenantId: tenantData?.name ?? `${tenantId?.substring(0, 7)}...` ?? 'current tenant',
    snapshotId: snapshotData?.name ?? `${snapshotId?.substring(0, 7)}...` ?? 'current snapshot',
    subscriptionId: subscriptionData?.name ?? `${subscriptionId?.substring(0, 7)}...` ?? 'current subscription',
  }

  const labels = {
    products: "applications",
    apikeys: "API Keys",
  }

  const routeComponents = router.asPath
    .replace(window.location.hash, '')
    .substring(0, router.asPath.indexOf('?') > 1 ? router.asPath.indexOf('?') : router.asPath.length)
    .split('/')

  const homeCrumb = {
    label: 'Home',
    url: '/',
  }

  const mappedRouteComponents = router.route === '/' ? [homeCrumb] : router.route
    .split('/')
    .map((component, index) => {
      if (component === '') {
        return homeCrumb
      }

      if (component.match(/^\[\w+\]$/)) {
        const match = component.replace('[', '').replace(']', '')
        return {
          label: data[match],
          url: routeComponents.slice(0, index + 1).join('/'),
        }
      }

      return {
        label: labels[routeComponents[index]] || routeComponents[index],
        url: routeComponents.slice(0, index + 1).join('/'),
      }
    })

  return <>
    <Breadcrumb>
      {mappedRouteComponents.map((routeComponent) => (
        <Breadcrumb.Item
          key={`breadcrumbs-${routeComponent.url}`}
          href={routeComponent.url}
          linkAs={Link}
          active={router.asPath === routeComponent.url}
        >{routeComponent.label}</Breadcrumb.Item>
      ))}
    </Breadcrumb>

    <style global jsx>{`
        .breadcrumb {
          margin- bottom: 0;
      }
    `}</style>
  </>
}