import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { PropsWithChildren } from 'react';

interface Props {
  href: string;
  label?: string;
  variant?: 'icon' | 'underline';
  disabled?: boolean;
}

export default function ExternalLink({
  href,
  label,
  variant = 'icon',
  disabled = false,
  ...props
}: PropsWithChildren<Props>) {
  const classes = [
    variant === 'underline' ? 'text-decoration-underline' : '',
    disabled ? 'text-muted' : '',
  ]

  return (
    <Link href={href}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        onClick={(evt) => evt.stopPropagation()}
        className={classes.join(' ')}
      >
        {label || props.children}
        {variant === 'icon' && <FontAwesomeIcon
          icon={faExternalLinkAlt}
          color="#ccc"
          height="1em"
          width="1em"
          className="ms-1"
        />}
      </a>
    </Link>
  )
}
