import ExternalScript from "components/external-script";
import { fetchChargebeeSession, createChargebeeCustomer } from "lib/chargebee/chargebee-fetcher";
import { useChargebeeCustomer } from "lib/use-chargebee";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useSubscription, useSubscriptionUsers } from "lib/use-subscriptions";
import { useChargeBeeSiteInfo } from "lib/use-info";

function getChargebee(siteKey: string) {
  // @ts-ignore
  const cb = window.Chargebee;

  try {
    return cb.getInstance();
  } catch (error) {
    return cb.init({
      site: siteKey,
      isItemsModel: true,
    });
  }
}

export default function ChargebeePortalButton({
  subscriptionId,
}: {
  subscriptionId: string;
}) {
  const { data: subscription, isValidating: subscriptionIsValidating, updateHasPaymentDetails, updateHasBillingAddress, mutate } = useSubscription(subscriptionId as string)
  const { data: cbSiteInfo, isValidating: cbSiteInfoValidating } = useChargeBeeSiteInfo();
  const { data: chargebeeCustomer, isValidating: chargeebeeCustomerValidating, mutate: mutateChargebeeCustomer } = useChargebeeCustomer(subscriptionId as string, {
    shouldRetryOnError: false, // When the customer does not exist, a 404 is returned. That is sufficient information.
    revalidateOnFocus: false,
  })
  const { data: userData } = useSubscriptionUsers(subscriptionId as string)

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (chargebeeCustomer == null) { return; }

    const hasBillingAddress = chargebeeCustomer.billing_address != null;
    if (subscription.hasBillingAddress != hasBillingAddress) {
      updateHasBillingAddress(hasBillingAddress);
      mutate();
    }

  }, [chargebeeCustomer])

  useEffect(() => {
    if (chargeebeeCustomerValidating || cbSiteInfoValidating || subscriptionIsValidating) { return; }
    setLoading(false)
  }, [chargeebeeCustomerValidating, cbSiteInfoValidating, subscriptionIsValidating])

  return (
    <>
      {!loading &&
        <>
          <ExternalScript
            src="https://js.chargebee.com/v2/chargebee.js"
            data-cb-site={cbSiteInfo.site}
          />
          <Button
            onClick={async () => {
              if (!chargebeeCustomer) {
                try {
                  await createChargebeeCustomer(subscriptionId as string, userData[0].email, subscription.name)
                } catch (error) {
                  console.error(error)
                }
              }

              const cb = getChargebee(cbSiteInfo.site);
              cb.setPortalSession(() => fetchChargebeeSession(subscriptionId));
              const portal = cb.createChargebeePortal();


              const billingCallbacks = {
                close: function () {
                  mutateChargebeeCustomer();
                }
              };

              const callbacks = {
                paymentSourceAdd: function () {
                  updateHasPaymentDetails(true);
                  if (!subscription.hasBillingAddress) {
                    cb.closeAll();
                    portal.open(billingCallbacks, { sectionType: 'portal_edit_billing_address' });
                  }
                },
                close: function () {
                  mutateChargebeeCustomer();
                }
              }

              const initialPage = !subscription.hasPaymentDetails ? { sectionType: 'portal_add_payment_method' } : !subscription.hasBillingAddress ? { sectionType: 'portal_edit_billing_address' } : null
              portal.open(callbacks, initialPage);
            }}
          >Manage billing details</Button>
        </>
      }
      {
        loading &&
        <>
          <Button disabled={true}>
            <Spinner
              animation="border"
              as="span"
              size="sm"
              role="status"
              aria-hidden="true"
            ></Spinner>
          </Button>
        </>
      }
    </>
  )
}
