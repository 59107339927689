import { Claim } from 'lib/types'
import useFeatureClaims from 'lib/use-feature-claims'
import { ReactChild, useEffect, useState } from 'react'

type Props = {
  productId?: string;
  claim: Claim;
  children: ReactChild | ReactChild[];
}

export default function ClaimContainer({
  productId = null,
  claim,
  children,
}: Props) {
  const [shouldRender, setShouldRender] = useState(false)
  const { data: claims } = useFeatureClaims(productId)

  useEffect(() => {
    setShouldRender(claims.includes(claim))
  }, [claim, claims])

  return (
    <>
      {shouldRender && children}
    </>
  )
}