import useUserProfile from "lib/use-userprofile"
import { useRouter } from "next/router";
import { useEffect } from "react";


export default function RegionRedirecter(
) {

  if (typeof window === undefined || (window != null && window.location.host !== process.env.NEXT_PUBLIC_REGION_NEUTRAL_HOST)) {
    return (<></>)
  }

  const { data: profile } = useUserProfile();
  const router = useRouter();

  useEffect(() => {
    if (profile == null) { return; }

    if (profile.preferredRegion == null) { return; }

    window.location.replace(`https://console.${profile.preferredRegion}.wpcs.io${router.asPath}`);
  }, [profile]);

  return (<></>)
}