import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from 'components/side-bar';
import { useState } from 'react';

export default function HelpButton() {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <>
      <div className='help-button dropshadow-1' role="button" onClick={toggleSidebar}>
        <FontAwesomeIcon
          icon={faQuestion}
          height="30px"
          width="30px"
          color="white" />
      </div>

      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />


      <style jsx>{`
      .help-button {
        padding: 10px;
        position: fixed;
        right: 25px;
        bottom: 25px;
        background: #6a78d1;
        cursor: pointer;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
      }

      .help-button:hover {
        background: #7887eb;
      }

      .help-button:focus{
        background: #7887eb;
      }
      `}</style>
    </>
  )
}
