interface NameComparable {
  name: string;
}

interface DateCreatedComparable {
  dateCreated: string;
}

interface DateChangedComparable {
  dateChanged: string;
}

export enum SortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export function sortByDateCreated<T extends DateCreatedComparable>(order: SortOrder = SortOrder.DESCENDING) {
  return (a: T, b: T) => {
    const dateA = new Date(a.dateCreated).getTime();
    const dateB = new Date(b.dateCreated).getTime();

    if (order === SortOrder.DESCENDING) {
      return dateB - dateA;
    }
    
    return dateA - dateB;
  }
}

export function sortByDateChanged<T extends DateChangedComparable>(order: SortOrder = SortOrder.DESCENDING) {
  return (a: T, b: T) => {
    const dateA = new Date(a.dateChanged).getTime();
    const dateB = new Date(b.dateChanged).getTime();

    if (order === SortOrder.DESCENDING) {
      return dateB - dateA;
    }
    
    return dateA - dateB;
  }
}

export function sortByName<T extends NameComparable>(order: SortOrder = SortOrder.ASCENDING) {
  return (a: T, b: T) => {
    if (order === SortOrder.DESCENDING) {
      return b.name.localeCompare(a.name);
    }
    
    return a.name.localeCompare(b.name);
  }
}

export function sortByValue(order: SortOrder = SortOrder.ASCENDING) {
  return (a: string, b: string) => {
    if (order === SortOrder.DESCENDING) {
      return b.localeCompare(a);
    }
    
    return a.localeCompare(b);
  }
}
