import { ServicePlan } from 'lib/types'
import useProduct from 'lib/use-product';
import { useSubscription } from 'lib/use-subscriptions';
import { ReactChild, useEffect, useState } from 'react'

type Props = {
  productId: string;
  serviceLevels: ServicePlan[];
  children: ReactChild | ReactChild[];
}

export default function ServicePlanContainer({
  productId,
  serviceLevels,
  children,
}: Props) {
  const [shouldRender, setShouldRender] = useState(false)
  const { data: product } = useProduct(productId);
  const { data: subscription } = useSubscription(product?.subscriptionId);

  useEffect(() => {
    setShouldRender(subscription != null && serviceLevels.includes(subscription.servicePlanSlug || ''))
  }, [serviceLevels, subscription])

  return (
    <>
      {shouldRender && children}
    </>
  )
}