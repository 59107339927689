import { faExclamationTriangle, faInfoCircle, faLightbulb, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";

export function Warn({ className = "", variant = "light", children }) {
  return (
    <Alert variant={variant} className={`${className} mt-4 d-flex align-items-center`}><FontAwesomeIcon className="flex-shrink-0 note-icon" icon={faExclamationTriangle} height="25px" width="25px" /><div className="ms-3 note-paragraph-container">{children}</div></Alert>
  );
}

// Something people have to know
export function Note({ className = "", variant = "light", children }) {
  return (
    <Alert variant={variant} className={`${className} mt-4 d-flex align-items-center`}><FontAwesomeIcon className="flex-shrink-0 note-icon" icon={faInfoCircle} height="25px" width="25px" /><div className="ms-3 note-paragraph-container">{children}</div></Alert>
  );
}

// Something people should know
export function Inform({ className = "", variant = "primary", children }) {
  return (
    <Alert variant={variant} className={`${className} mt-4 d-flex align-items-center`}><FontAwesomeIcon className="flex-shrink-0 note-icon" icon={faInfoCircle} height="25px" width="25px" /><div className="ms-3 note-paragraph-container">{children}</div></Alert>
  );
}

export function Tip({ className = "", variant = "warning", children }) {
  return (
    <Alert variant={variant} className={`${className} mt-4 d-flex align-items-center`}><FontAwesomeIcon className="flex-shrink-0 note-icon" icon={faLightbulb} height="25px" width="25px" /><div className="ms-3 note-paragraph-container">{children}</div></Alert>
  );
}

export function Action({ className = "", variant = "secondary", children }) {
  return (
    <Alert variant={variant} className={`${className} mt-4 d-flex align-items-center`}><FontAwesomeIcon className="flex-shrink-0 note-icon" icon={faPlayCircle} height="25px" width="25px" /><div className="ms-3 note-paragraph-container">{children}</div></Alert>
  );
}