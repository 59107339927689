import ExternalLink from "components/external-link";

export default function ApiKeyHelpContent() {
  return <>
    <p>API Keys are used for authentication and authorization when consuming the wildcloud API. The API can be used to create certain automations. Next to that, the API is used by the Import plugin and our Storefront plugins.</p>
    <p>Try to name API keys to the location where they are being used, so that if a certain place is compromised, you can quickly remove any compromised API key or recycle the specific API key's secret.</p>
    <p>Secrets are only shown once, store them in a secure location.</p>

    <hr className="text-brand-primary" />

    <p>Related documentation: </p>
    <ul style={{ listStyleType: "none" }}>
      <li><ExternalLink href="https://docs.wpcs.io/api/" label="API Documentation" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/import-from-existing-wordpress-site" label="Import an existing WP site" /></li>
    </ul>
  </>
}