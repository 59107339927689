import { Subscription, useSubscriptions } from "lib/use-subscriptions";
import React, { useEffect, useMemo, useState } from "react";
import useFeatureClaims from "lib/use-feature-claims";
import { sortByDateCreated, SortOrder } from "lib/sort-functions";
import Dayjs from "dayjs";
import { useRouter } from "next/router";

export default function ServicePlanNag() {
  const router = useRouter();
  const [renderNags, setRenderNags] = useState(false);

  const { data: subscriptions } = useSubscriptions();
  const { data: featureClaims } = useFeatureClaims();

  const subscriptionsToNag = useMemo<Subscription[]>(() => {
    if (!subscriptions) {
      return [];
    }

    return subscriptions
      .sort(sortByDateCreated(SortOrder.ASCENDING))
      .filter(sub => sub.managedByGlobal // If subscription is managed by global show serviceplan nag, else show other
        && sub.partnerId == null // If subscription has a partner associated with it, never show billing nags.
        && !sub.excludedFromBilling // If sub is excluded from billing, obviously no billing nag should be shown.
        && !sub.hasPaymentDetails) // hasPaymentDetails indicates a service plan has been chosen, no nag required.
      .slice(0, 3) // show max nags at the same time so the screen is never flooded.
  }, [subscriptions])

  useEffect(() => {
    if (subscriptionsToNag.length < 1) {
      return;
    }

    if (!renderNags) {
      return;
    }

    const subscriptionsToRedirect = subscriptionsToNag
      .filter(sub => Dayjs(sub.trialEndDate).isBefore(Dayjs()));

    if (subscriptions.length === 1 && subscriptionsToRedirect.length === 1) {
      router.replace(`https://${process.env.NEXT_PUBLIC_GLOBAL_CONSOLE_DOMAIN}/billing/${subscriptionsToRedirect[0].id}/service-plan`)
    }
  }, [subscriptionsToNag])

  useEffect(() => {
    if (!featureClaims) {
      return;
    }

    setRenderNags(!featureClaims.includes('Subscription.Manage'))
  }, [featureClaims]);

  if (!renderNags) {
    return null
  }

  return (
    <></>
  )
}

