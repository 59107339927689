import useSWR, { SWRResponse } from 'swr'

interface RegionInfo {
  name: string;
  dns: {
    cname: string;
    ips: string[];
  }
}

interface SupportedVersionsInfo {
  wp: string[];
  php: string[];
}

interface ChargeBeeSiteInfo {
   site: string;
}

export default function useRegionInfo(): SWRResponse<RegionInfo, Error> {
  return useSWR('/api/v1/info/region', {
    refreshInterval: 0,
  });
}

export function useSupportedVersionsInfo(): SWRResponse<SupportedVersionsInfo, Error> {
  return useSWR('/api/v1/info/supported-versions', {
    refreshInterval: 0,
  });
}

export function useChargeBeeSiteInfo(): SWRResponse<ChargeBeeSiteInfo, Error> {
  return useSWR('/api/v1/info/chargebee-site', {
    refreshInterval: 0,
  });
}
