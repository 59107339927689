import { Subscription, useSubscriptions } from "lib/use-subscriptions";
import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Modal, Row } from "react-bootstrap";
import ChargebeePortalButton from "./buttons/chargebee-portal-button";
import InternalLink from "./internal-link";
import Dayjs from 'dayjs'
import useFeatureClaims from "lib/use-feature-claims";
import { sortByDateCreated, SortOrder } from "lib/sort-functions";
import { getSubscriptionUrl, isSubscriptionInTrial } from "lib/helpers";
import Link from "next/link";

function Nag({
  sub,
  children,
}: {
  sub: Subscription;
  children: any;
}) {
  const [variant, setVariant] = useState('primary');

  useEffect(() => {
    if (Dayjs().isAfter(Dayjs(sub?.trialEndDate).subtract(1, 'weeks'))) {
      setVariant('danger');
    } else if (Dayjs().isAfter(Dayjs(sub?.trialEndDate).subtract(2, 'weeks'))) {
      setVariant('warning');
    }
  }, [sub])

  return (
    <Alert variant={variant}>
      <Row>
        <Col className="d-flex align-items-center">
          {children}
        </Col>
        <Col xs={4} className="text-end">
          <ChargebeePortalButton subscriptionId={sub?.id} />
        </Col>
      </Row>
    </Alert>
  )
}

export default function BillingNag() {
  const [renderNags, setRenderNags] = useState(false);

  const { data: subscriptions } = useSubscriptions();
  const { data: featureClaims, isValidating } = useFeatureClaims();

  useEffect(() => {
    if (isValidating) {
      return;
    }

    setRenderNags(!featureClaims.includes('Subscription.Manage'))
  }, [featureClaims, isValidating])

  if (!renderNags) {
    return null
  }

  return (
    <Container>
      <ForcedNag />
      {subscriptions && subscriptions
        .sort(sortByDateCreated(SortOrder.ASCENDING))
        .filter(sub => sub.partnerId == null)
        .filter(sub => !sub.hasPaymentDetails || !sub.hasBillingAddress)
        .filter(sub => !sub.requiresPaymentDetails) // If payment details are required, the nag should be an undismissable component
        .filter(sub => !sub.managedByGlobal) // Globally managed subscriptions should not ask for billing details, handled by the Service Plan Nag
        .slice(0, 3) // show max nags at the same time so the screen is never flooded
        .map((sub, i) => (
          <Nag sub={sub} key={i}>
            {!(sub.hasPaymentDetails) &&
              <span>
                Please complete the billing details for your subscription <InternalLink href={getSubscriptionUrl(sub)}>{sub.name}</InternalLink>
              </span>
            }
            {sub.hasPaymentDetails && !sub.hasBillingAddress &&
              <span>Oops, looks like we don't have a billing address for subscription <InternalLink href={getSubscriptionUrl(sub)}>{sub.name}</InternalLink></span>
            }
          </Nag>
        ))}
    </Container>
  )
}

function ForcedNag() {
  const { data: subscriptions } = useSubscriptions();
  const [subscription, setSubscription] = useState<Subscription>(null)

  useEffect(() => {
    if (!subscriptions) {
      return;
    }

    setSubscription(subscriptions
      .filter(sub => sub.partnerId == null)
      .filter(sub => !sub.hasPaymentDetails || !sub.hasBillingAddress)
      .filter(sub => !sub.managedByGlobal)
      .find(sub => sub.requiresPaymentDetails))
  }, [subscriptions])

  const isTrial = isSubscriptionInTrial(subscription);

  return (
    <>
      {subscription &&
        <Modal
          show={!!subscription}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Billing details required for {subscription?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {isTrial && <>
              <Nag sub={subscription}>
                {!(subscription.hasPaymentDetails) &&
                  "To continue your trial, please add a valid credit card and your billing information."
                }
                {subscription.hasPaymentDetails && !subscription.hasBillingAddress &&
                  "Oops, looks like we're missing your billing address. Hit the button to fill it out."
                }
              </Nag>
              <Alert variant="light">
                <div>
                  Don't worry though, we won't start billing until after your trial is over.
                </div>
              </Alert>
            </>
            }
            {!isTrial && <>
              <Nag sub={subscription}>
                {!subscription.hasPaymentDetails &&
                  "To continue, please add a valid credit card and your billing information."
                }
                {subscription.hasPaymentDetails && !subscription.hasBillingAddress &&
                  "Oops, looks like we're missing your billing address. Hit the button to fill it out."
                }
              </Nag>
              <span className="small muted"><Link href={getSubscriptionUrl(subscription)}>or cancel your subscription</Link></span>
            </>
            }
          </Modal.Body>
        </Modal>
      }
    </>
  )
}
