import {
  Modal,
} from 'react-bootstrap';
import SupportForm from 'components/forms/support-form';

export default function SupportModal({
  show,
  onHide = () => { }
}) {

  const cancel = () => { onHide(); }
  const submitted = () => { onHide(); }

  return <>
    <Modal show={show} onHide={cancel} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Support request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SupportForm onCancel={cancel} onSubmitted={submitted}></SupportForm>
      </Modal.Body>
    </Modal>
  </>
}