import ExternalLink from "components/external-link";

export default function ApplicationOverviewHelpContent() {
  return <>
    <p>This frequently accessed screen has a lot of functionality:</p>
    <ul>
      <li>Use the <b>Action button</b> to: create a tenant, import a tenant, create a version, rename the application or, delete the application</li>
      <li>Use the <b>Version cards</b> to go into the Version editor, deploy a new snapshot or turn off the version.</li>
      <li>In the <b>tenants list</b> you can view and access your tenants. Use the select boxes to do bulk deletion, moves or activation or deactivation of plugins for the selected tenants.</li>
    </ul>

    <hr className="text-brand-primary" />

    <p>Related documentation: </p>
    <ul style={{ listStyleType: "none" }}>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/versions" label="Version documentation" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/set-a-version-as-production" label="The production version" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/shutting-down-the-version-editor" label="Turning off a version" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/deploy-a-version-snapshot" label="Deploying a snapshot" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/create-a-tenant" label="Creating a tenant" /></li>
    </ul>
  </>
}