import { signOut } from 'next-auth/react';
import HttpError from './http-error';

export default function fetcher(url: string): any {
  return fetch(url)
    .then(async (res) => {
      if (res.status === 401) {
        await signOut({ callbackUrl: window.location.href, redirect: false });
        return;
      }

      if (res.status > 299) {
        const error = new HttpError(res.statusText);
        error.status = res.status;
        throw error;
      }

      return res
    })
    .then(res => res.json())
}