import useVersionList from "lib/use-version-list";
import NavbarButtonBase from "./navbar-button-base";

export default function NavbarTenantCreateButton({
  productId,
}) {
  const { data: versionsList } = useVersionList(productId)

  return (
    <NavbarButtonBase
      label="New tenant"
      href={`/products/${productId}/tenants/new`}
      disabled={versionsList?.length === 0 || false}
      disabledExplanation="You need at least one version to create a tenant in"
    />
  )
}
