import useSWR, { SWRConfiguration, SWRResponse, mutate } from 'swr'
import { Version } from './types'

interface VersionSWRResponse extends SWRResponse<Version, Error> {
  setActive: (active: boolean) => Promise<void>;
}

export default function useVersion(productId: string, versionId: string, config?: SWRConfiguration): VersionSWRResponse {
  const swr = useSWR(productId && versionId ? `/api/v1/products/${productId}/versions/${versionId}` : null, config);

  async function mutateVersion(data) {
    await Promise.all([
      data == null ? swr.mutate() : swr.mutate(data, true),
      mutate(`/api/v1/products/${productId}/versions`)
    ]);
  }

  return {
    ...swr,
    setActive: async (active) => {
      await setActive(productId, versionId, active);
      await mutateVersion({ ...swr.data, active: active })
    }
  }
}

async function setActive(productId, versionId, active): Promise<void> {
  const response = await fetch(`/api/v1/products/${productId}/versions/${versionId}/active`, {
    method: "POST",
    body: JSON.stringify({ active })
  });

  if (!response.ok) {
    const res = await response.json()
    throw new Error(res.message);
  }
}