import { useRouter } from 'next/router';
import { Nav, Offcanvas } from 'react-bootstrap';
import { useMemo, useState } from 'react';
import SupportModal from 'components/modals/support-modal';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findContent } from 'lib/find-sidebar-content';
import { sideBarHelpContent } from 'components/side-bar-help-content';
import { isConfirmKey } from 'lib/accessibility-helpers';

export default function Sidebar({ isOpen, toggleSidebar }) {
  const router = useRouter();

  const [showSupport, setShowSupport] = useState(false);
  const openSupport = () => {
    setShowSupport(true);
  };

  const { title, content } = useMemo(() => findContent(sideBarHelpContent, router.route), [router.route]);

  return (
    <>
      <Offcanvas show={isOpen} closeButton className="helpbar-bg-image" onHide={() => toggleSidebar()} placement="end">
        <Offcanvas.Header closeVariant="white" closeButton className="">
          <Offcanvas.Title className="text-brand-primary">{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          <Nav className="flex-column">
            <Nav.Item>
              <div className="menu-item text-brand-primary level-1 my-2">
                {content}
              </div>
            </Nav.Item>
          </Nav>

          <Nav className="flex-column">
            <Nav.Item>
              <div className="menu-item text-brand-primary">
                <hr style={{ "color": "white" }} />
                Need support? Use the link below to contact support
              </div>
              <div onClick={openSupport}
                onKeyDown={(e) => isConfirmKey(e) && openSupport()}
                className="menu-item text-brand-primary link"
                role="button"
                tabIndex={0}>Contact support<FontAwesomeIcon
                  className="ms-1 text-brand-primary"
                  icon={faExternalLinkAlt}
                  height={13}
                  width={13}
                /></div>

            </Nav.Item>
            <Nav.Item></Nav.Item>
          </Nav>
        </Offcanvas.Body>
        <SupportModal show={showSupport} onHide={() => setShowSupport(false)}></SupportModal>
      </Offcanvas>

      <style jsx>{`
        .menu-item {
          padding: 20px;
          font-size: 1.1rem;
        }

        .link { 
          cursor: pointer;
        } 
     `}</style></>
  );
};