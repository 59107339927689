import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import { Domain, Tenant } from './types'

interface TenantDomains {
  tenantId: string;
  primaryDomain: Domain;
  redirectDomains: Domain[];
}

export function useTenants(productId: string, config?: SWRConfiguration): SWRResponse<Tenant[], Error> {
  return useSWR(productId ? `/api/v1/products/${productId}/tenants` : null, config)
}

export function useTenantsForVersion(productId: string, versionId: string, config?: SWRConfiguration): SWRResponse<Tenant[], Error> {
  const tenantSWR = useTenants(productId, config);

  const newSWR = {
    ...tenantSWR,
    data: tenantSWR.data?.filter(tenant => tenant.version.id === versionId) || tenantSWR.data,
  }

  return newSWR;
}

export function useTenant(productId: string, tenantId: string, config: SWRConfiguration = {}): SWRResponse<Tenant, Error> {
  return useSWR(productId && tenantId ? `/api/v1/products/${productId}/tenants/${tenantId}` : null, {
    ...config,
  })
}

export function useTenantDomains(productId: string, tenantId: string, config: SWRConfiguration = {}): SWRResponse<TenantDomains, Error> {
  return useSWR(productId && tenantId ? `/api/v1/products/${productId}/tenants/${tenantId}/domain` : null, {
    ...config,
  })
}
