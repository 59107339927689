import ExternalLink from "components/external-link";
import { Note } from "components/notes";

export default function ImportVersionHelpContent() {
  return <>
    <p>Importing a site to the wildcloud platform can be done using our migration plugin. Follow the wizard.</p>
    <p>Always make sure that there is enough available disk space on the source hosting to copy the site entirely.</p>

    <Note><p>Not all hosting solutions work well with the migration plugin due to enforced maximum request times. If you're on a managed WP hosting solution another alternative is using Prime Mover.</p></Note>

    <hr className="text-brand-primary" />

    <p>Related documentation: </p>
    <ul style={{ listStyleType: "none" }}>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/import-from-existing-wordpress-site#export-tenant" label="Import a tenant" /></li>
    </ul>
  </>
}