import { Form, Row, Col, Button, Spinner, } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { useRouter } from 'next/router';
import { faBug, faCalendarCheck, faComment, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { Inform, Tip } from 'components/notes';
import ExternalLink from 'components/external-link';
import { useSubscriptions } from 'lib/use-subscriptions';
import { isConfirmKey } from 'lib/accessibility-helpers';

const schema = yup.object().shape({
  subject: yup.string().required(),
  description: yup.string().required()
})

export default function SupportForm({
  onSubmitted = () => { },
  onCancel = () => { }
}) {
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const router = useRouter();
  const { data: subscriptions } = useSubscriptions();
  const [category, setCategory] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const onSubmit = (data) => {
    return fetch(`/api/v1/support`, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
        requestPage: router.asPath,
        category
      })
    }).then(() => { onSubmitted() })
      .catch((err) => alert(`Something went wrong: ${err.message}`));
  }

  const isEssential = useMemo(() => {
    if (subscriptions == null || subscriptions.length == 0) { return null; }
    return subscriptions.every(s => s.servicePlanSlug == 'essential');
  }, [subscriptions])

  useEffect(() => {
    setShowInfo(isEssential)
  }, [isEssential])

  return <>
    {category &&
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Label>What are you contacting us about?</Form.Label>
        <Form.Control
          name="subject"
          type="text"
          ref={register}
          required
          isInvalid={!!errors.subject}
          className="mb-3"
        />
        <Form.Control.Feedback type="invalid">
          <div>{errors.subject?.message}</div>
        </Form.Control.Feedback>
        <Form.Label>Could you describe the details</Form.Label>
        <Form.Control
          name="description"
          type="textarea"
          as="textarea"
          ref={register}
          style={{ height: '300px' }}
          required
          className="mb-3"
          isInvalid={!!errors.description}
        />
        <Form.Control.Feedback type="invalid">
          <div>{errors.description?.message}</div>
        </Form.Control.Feedback>
        <hr />
        <Row>
          <Col className="text-end">
            <Button variant="secondary" className="me-3" onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Submit
              {' '}
              {formState.isSubmitting && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
            </Button>
          </Col>
        </Row>
      </Form>
    }
    {category == null && !showInfo &&
      <>
        <Row>
          <Col>
            <div className="support-icon-container dropshadow-1" onClick={() => setCategory("bug")} role='button' tabIndex={0} aria-roledescription='Report a bug' onKeyDown={(e) => isConfirmKey(e) && setCategory("bug")}>
              <FontAwesomeIcon
                icon={faBug}
                color="#808080"
                height="150px"
              />
              <h4>Report a bug</h4>
            </div>
          </Col>
          <Col>
            <div className="support-icon-container dropshadow-1" onClick={() => setCategory("question")} role='button' tabIndex={0} aria-roledescription='Ask a question' onKeyDown={(e) => isConfirmKey(e) && setCategory("question")}>
              <FontAwesomeIcon
                icon={faQuestion}
                color="#808080"
                height="150px"
              />
              <h4>Ask a question</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="support-icon-container dropshadow-1" onClick={() => setCategory("feedback")} role='button' tabIndex={0} aria-roledescription='Give feedback' onKeyDown={(e) => isConfirmKey(e) && setCategory("feedback")}>
              <FontAwesomeIcon
                icon={faComment}
                color="#808080"
                height="150px"
              />
              <h4>Give feedback</h4>
            </div>
          </Col>
          <Col>
            <div className="support-icon-container dropshadow-1" onClick={() => router.push(process.env.HUBSPOT_REQUEST_CALL_URL)} role='button' tabIndex={0} aria-roledescription='Give feedback' onKeyDown={(e) => isConfirmKey(e) && router.push(process.env.HUBSPOT_REQUEST_CALL_URL)}>
              <FontAwesomeIcon
                icon={faCalendarCheck}
                color="#808080"
                height="150px"
              />
              <h4>Book a call</h4>
            </div>
          </Col>
        </Row>
      </>
    }
    {isEssential && showInfo &&
      <>
        <Inform>
        <p>Take into consideration that if the problem you are referring is not a bug in the Wildcloud system you should upgrade your service plan to <b>Professional</b> in order to get personal support.</p>
        </Inform>
        <Col className="text-end">
          <Tip>
            <p>Check our <ExternalLink href="https://docs.wpcs.io/docs/integrations/getting-started" label="documentation" /> or <ExternalLink href="https://wpcs.io/knowledge-base/" label="knowlegde base" /></p>
          </Tip>
          <Button href={`https://${process.env.NEXT_PUBLIC_GLOBAL_CONSOLE_DOMAIN}/billing/${subscriptions?.[0].id}/service-plan`}>
            Upgrade to Professional
          </Button>
          <Button variant='link' onClick={() => setShowInfo(false)}>Submit request</Button>
        </Col>
      </>
    }

    <style jsx>{`
      .support-icon-container {
        margin: 5px;
        padding: 15px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
      } 

      .support-icon-container:hover {
        background: #f1f1f1
      }

    `}</style>
  </>
}
