import ExternalLink from "components/external-link";

export default function CreateTenantHelpContent() {
  return <>
    <p>Creating a new tenant involves a few decisions. Every tenant always runs under a specific version. The latest snapshot of this version determines the plugins, themes and language files available to the tenant you're about to create.</p>
    <p>While the latest snapshot determines the code your tenant will run on, you're also able to select a snapshot to use for provisioning this tenant. The new tenant will be provisioned with the database export of the selected snapshot, as well as its uploads.</p>
    <p>The name of the tenant is important, but can always be changed later. Mind you that the base domain of the tenant, the read-only domain name field under the name, will not change when changing the tenant name.</p>
    <p>The base domain of the tenant in the read-only field is an immutable domain name attached to your tenant that cannot be deleted. If you want to add custom domain names to the tenant you can do so in the tenant detail screen after the tenant is provisioned.</p>
    <p>Before creation, you can already define some PHP Constants that the tenant should have. This is usefull for adding activation keys or working with the Storefront WaaS-Client plugin to test out certain Tenant roles.</p>

    <hr className="text-brand-primary" />

    <p>Related documentation: </p>
    <ul style={{ listStyleType: "none" }}>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/create-a-tenant" label="Creating a tenant" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/manage-php-constants" label="PHP Constants" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/tenant-snapshots" label="Tenant snapshots" /></li>
    </ul>
  </>
}