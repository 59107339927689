import { Subscription, useSubscriptions } from "lib/use-subscriptions";
import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import useFeatureClaims from "lib/use-feature-claims";
import { sortByDateCreated, SortOrder } from "lib/sort-functions";
import { Warn } from "./notes";
import ExternalLink from "./external-link";

export default function QuarantinedNag() {
  const [renderNags, setRenderNags] = useState(false);

  const { data: subscriptions } = useSubscriptions();
  const { data: featureClaims } = useFeatureClaims();

  const subscriptionsToNag = useMemo<Subscription[]>(() => {
    if (!subscriptions) {
      return [];
    }

    return subscriptions
      .filter(sub => sub.quarantined)
      .sort(sortByDateCreated(SortOrder.ASCENDING))
      .slice(0, 3) // show max nags at the same time so the screen is never flooded.
  }, [subscriptions])

  useEffect(() => {
    if (!featureClaims) {
      return;
    }

    setRenderNags(!featureClaims.includes('Subscription.Manage'))
  }, [featureClaims]);

  if (!renderNags) {
    return null
  }

  return (
    <Container>
      {subscriptionsToNag?.map(sub => (
        <Warn variant="danger" key={sub.id}>
          <p>Your subscription has been Quarantined due to suspect behaviour. To unlock your subscription please contact <ExternalLink label="support" href={`mailto:support@wildcloud.so?subject=Check quarantined subscription ${sub.name} - ${sub.id}`} /></p>
        </Warn>
      ))}
    </Container>
  )
}
