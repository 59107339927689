import useSWR, { mutate, SWRResponse } from 'swr'
import { IStatus, Product, ServicePlan, WPCS } from './types';

export interface Subscription extends WPCS.Resource {
  requiresPaymentDetails: boolean;
  hasPaymentDetails: boolean;
  managedByGlobal: boolean;
  datePaymentDetailsDone: string;
  locked: boolean;
  lockReason: string;
  dateLocked: string;
  canDelete: boolean;
  status: IStatus;
  isSuspended: boolean;
  dateSuspended: string;
  partnerId: string;
  hasBillingAddress: boolean;
  isPaymentPaused: boolean;
  trialStartDate: string;
  trialEndDate: string;
  shouldActivateTrial: boolean;
  excludedFromBilling: boolean;
  productCount: number;
  servicePlanSlug: ServicePlan;
  quarantined: boolean;
  trusted: boolean;
}

interface User {
  email: string
  firstName: string;
  fullName: string;
  lastName: string;
  picture: string;
  userId: string;
  createdAt: string;
  lastLogin: string;
  loginsCount: number;
}

interface SubscriptionSWRResponse extends SWRResponse<Subscription, Error> {
  updatePaymentDetailsRequired: (isRequired: boolean) => Promise<void>;
  updateHasPaymentDetails: (hasDetails: boolean) => Promise<void>;
  updateLock: (isLocked: boolean, reason: string) => Promise<void>;
  updateSuspended: (suspended: boolean) => Promise<void>;
  updateHasBillingAddress: (hasBillingAddress: boolean) => Promise<void>;
  updatePaymentPaused: (paymentPaused: boolean) => Promise<void>;
  startTrial: () => Promise<void>;
}

async function updateSubscriptionPaymentRequired(subscriptionId: string, isRequired: boolean): Promise<void> {
  try {
    const body: Pick<Subscription, 'requiresPaymentDetails'> = {
      requiresPaymentDetails: isRequired,
    }

    const response = await fetch(`/api/v1/subscription/${subscriptionId}/requirespaymentdetails`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw error;
  }
}

async function updateSubscriptionHasPaymentDetails(subscriptionId: string, hasPaymentDetails: boolean): Promise<void> {
  try {
    const body: Pick<Subscription, 'hasPaymentDetails'> = {
      hasPaymentDetails,
    }

    const response = await fetch(`/api/v1/subscription/${subscriptionId}/haspaymentdetails`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw error;
  }
}

async function updateSubscriptionLock(subscriptionId: string, locked: boolean, lockReason: string): Promise<void> {
  try {
    const body: Pick<Subscription, 'locked' | 'lockReason'> = {
      locked,
      lockReason,
    }

    const response = await fetch(`/api/v1/subscription/${subscriptionId}/lock`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw error;
  }
}

async function updateSubscriptionSuspended(subscriptionId: string, isSuspended: boolean): Promise<void> {
  try {

    const body: Pick<Subscription, 'isSuspended'> = {
      isSuspended,
    }
    const response = await fetch(`/api/v1/subscription/${subscriptionId}/suspend`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw error;
  }
}

async function updatePaymentPaused(subscriptionId: string, isPaymentPaused: boolean): Promise<void> {
  try {

    const body: Pick<Subscription, 'isPaymentPaused'> = {
      isPaymentPaused,
    }
    const response = await fetch(`/api/v1/subscription/${subscriptionId}/paymentpaused`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw error;
  }
}

async function startTrial(subscriptionId: string): Promise<void> {
  try {

    const response = await fetch(`/api/v1/subscription/${subscriptionId}/startTrial`, {
      method: 'POST',
      body: "{}"
    })

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw error;
  }
}

export function useSubscription(subscriptionId: string): SubscriptionSWRResponse {
  const swr = useSWR<Subscription, Error>(subscriptionId ? `/api/v1/subscription/${subscriptionId}` : null);

  async function mutateSub(data): Promise<void> {
    // Mutate the resource and collection
    await Promise.all([
      data == null ? swr.mutate() : swr.mutate(data, true),
      mutate('/api/v1/subscription'),
    ]);
  }

  return {
    ...swr,
    updatePaymentDetailsRequired: async (isRequired: boolean) => {
      await updateSubscriptionPaymentRequired(swr.data.id, isRequired);
      await mutateSub({ ...swr.data, requiresPaymentDetails: isRequired })
    },
    updateHasPaymentDetails: async (hasDetails: boolean) => {
      await updateSubscriptionHasPaymentDetails(swr.data.id, hasDetails);
      await mutateSub({ ...swr.data, hasPaymentDetails: hasDetails });
    },
    updateLock: async (isLocked: boolean, reason: string) => {
      await updateSubscriptionLock(swr.data.id, isLocked, reason);
      await mutateSub({ ...swr.data, locked: isLocked, lockReason: reason });
    },
    updateSuspended: async (isSuspended: boolean) => {
      await updateSubscriptionSuspended(swr.data.id, isSuspended);
      await mutateSub({ ...swr.data, suspended: isSuspended });
    },
    updateHasBillingAddress: async (hasBillingAdd: boolean) => {
      await updateSubscriptionHasBillingAddress(swr.data.id, hasBillingAdd);
      await mutateSub({ ...swr.data, hasBillingAddress: hasBillingAdd });
    },
    updatePaymentPaused: async (isPaymentPaused: boolean) => {
      await updatePaymentPaused(swr.data.id, isPaymentPaused);
      await mutateSub({ ...swr.data, isPaymentPaused: isPaymentPaused });
    },
    startTrial: async () => {
      await startTrial(swr.data.id);
      await mutateSub(null);
    },
  }
}


async function updateSubscriptionHasBillingAddress(subscriptionId: string, hasBillingAddress: boolean): Promise<void> {
  try {
    const body: Pick<Subscription, 'hasBillingAddress'> = {
      hasBillingAddress,
    }

    const response = await fetch(`/api/v1/subscription/${subscriptionId}/hasBillingAddress`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw error;
  }
}

export function useSubscriptions(): SWRResponse<Subscription[], Error> {
  return useSWR('/api/v1/subscription', { refreshInterval: 0 });
}

export function useSubscriptionUsers(subscriptionId: string): SWRResponse<User[], Error> {
  return useSWR(subscriptionId ? `/api/v1/subscription/${subscriptionId}/user` : null, {
    refreshInterval: 0,
  })
}

export function useSubscriptionProducts(subscriptionId: string): SWRResponse<Product[], Error> {
  return useSWR(subscriptionId ? `/api/v1/${subscriptionId}/products` : null)
}
