import Image from 'next/image';
import { Spinner } from 'react-bootstrap';

export default function SplashScreen() {
  return (
    <div className="splash-screen justify-content-md-center align-items-center">
      <div className="splash-logo">
        <Image
          src="/img/wpcs_logo_cmyk_blue_tagline.png"
          alt=""
          width={371}
          height={137}
          priority
        />
        <div className="splash-spinner">
          <Spinner
            className="mt-3"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </div>
      </div>

      <style jsx>{`
        .splash-screen {
          position:relative;
          width: 100%;
          height: 100vh;
          background-color: white;
        }
        
        .splash-logo {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .splash-spinner {
          text-align: center;
          display: block;
          height: 50px;
        }
      `}</style>
    </div>
  )
}