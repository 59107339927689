import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

type Props = {
  href: string;
  label?: string;
}

export default function InternalLink({
  href,
  label,
  ...props
}: Props & React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  return (
    <Link href={href}>
      <a {...props}>
        {label || props.children}
        <FontAwesomeIcon
          className="ms-1"
          icon={faArrowCircleRight}
          color="#ccc"
          height={13}
          width={13}
        />
      </a>
    </Link>
  )
}