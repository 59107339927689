import Head from "next/head";

interface Props {
  src: string;
}

export default function ExternalScript({
  src,
  ...props
}: Props) {
  return (
    <Head>
      <script key={src} src={src} {...props} />
    </Head>
  )
}