import { useEffect } from 'react';
import { useSession, signIn } from 'next-auth/react'

export default function SessionChecker({
  onLoggedIn = () => { },
  allowNoSession = false,
}) {

  const { data: session, status } = useSession()

  const loading = status === "loading";

  useEffect(() => {
    if (!session && !loading && !allowNoSession) {
      signIn('auth0');
    } else if (session) {
      onLoggedIn();
    }
  }, [session, loading]);

  return (
    <>
    </>
  )
}