import ExternalLink from "components/external-link";

export default function SnapshotListHelpContent() {
  return <>
    <p>Snapshots have two functions on the wildcloud platform.</p>
    <p>Firstly, when you create a new version snapshot by hitting deploy, the deployment process will update all plugins, themes and language files that are available to the tenants running on that version.</p>
    <p>The other function snapshots have is to function as starting points for tenants (and other versions) with respect to content, i.e. the database and the wp-content folder. The latter except for plugins, themes and language files in the case of a new tenant.</p>
    <p>There are three types of snapshots:</p>
    <ul>
      <li><b>Version snapshots:</b> created by deploying a version.</li>
      <li><b>Tenant snapshots:</b> created explicitly from existing tenants.</li>
      <li><b>Custom snapshots:</b> created by the Migration plugin and the CLI when importing external sites.</li>
    </ul>

    <hr className="text-brand-primary" />

    <p>Related documentation: </p>
    <ul style={{ listStyleType: "none" }}>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/deploy-a-version-snapshot" label="Deploying a version snapshot" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/tenant-snapshots" label="Tenant snapshots" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/downloading-snapshots" label="Downloading snapshots" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/delete-a-snapshot" label="Deleting snapshots" /></li>
    </ul>
  </>
}