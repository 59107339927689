import ExternalLink from "components/external-link";

export default function CreateVersionHelpContent() {
  return <>
    <p>Versions determine the plugins, themes and language files that are available on the tenants running on that version. Therefore they allow for central management of the functionality that is exposed to tenants.</p>
    <p>A way to view versions is as increments of the WaaS or SaaS this application represents.</p>
    <p>Creating a new version is as easy as selecting a snapshot to base it on and a WordPress and PHP Version that the version (and its tenants) will have.</p>

    <hr className="text-brand-primary" />

    <p>Related documentation: </p>
    <ul style={{ listStyleType: "none" }}>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/create-a-version" label="" /></li>
      <li><ExternalLink href="https://docs.wildcloud.com/en/documentation/upgrading-wordpress-php-version" label="Upgrading the WP and PHP versions" /></li>
    </ul>
  </>
}